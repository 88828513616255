import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="my-4 text-center">404: Not Found</h1>
    <p className="my-4 text-center">It seems there has been a miscommunication and this page does not exist! Please try again or head <a href="/">home</a>.</p>
  </Layout>
)

export default NotFoundPage
